import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";

import "@egjs/react-view3d/css/view3d-bundle.min.css";
import Context from '../../hooks/context';
import ImageSlick from '../../components/commons/slick-slider';
import { Images } from '../../constants/Initial_states';
import CompareProduct from '../../components/compare-component/compare-product';

import queryString from 'query-string';
const Compare = () => {
    const router = useNavigate();
    const { type } = useParams();
    const location = useLocation();
    const queryParams: any = queryString.parse(location?.search);
    const idValues: any = queryParams.id || '';

    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState<{ glb: string; thumbImage: string[] }>({
        glb: '',
        thumbImage: [],
    });
    const contextData: any = useContext(Context);
    const handleClickCompare = () => {
        router('/');
    };
    // const handleFetchCompareById = async () => {
    //     const path = window.location.pathname;
    //     const finalString = path.split('/').pop();
    //     const type = finalString;
    //     if (contextData?.compareList?.length === 1) {
    //         router.push('/');
    //     } else {
    //         contextData.handleCompareList(type);
    //     }
    // };
    const handleClickOpenModal = (compare: any) => {
        setModal(true);
        setModalImage(compare);
    };

    useEffect(() => {
        if (contextData?.compareList?.length === 1) {
            router('/');
        }
    }, [contextData?.compareList]);


    useEffect(() => {

        contextData.handleCompareList(type);

    }, []);


    return (
        <>

            <CompareProduct ids={idValues} type={type} contextData={contextData} handleClickOpenModal={handleClickOpenModal} setModal={setModal} handleClickCompare={handleClickCompare} Images={Images} />
            <ImageSlick setModal={setModal} Images={modalImage.thumbImage} glbUrl={modalImage.glb} modal={modal} />

        </>
    );
};

export default Compare;