import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../pages/home/home';
import ProductUpload from '../pages/product/upload/product-upload';
import ProductDetail from '../pages/product-detail/detail/detail';
import Compare from '../pages/compare/compare';
import { ProtectedRoute } from '../services/protectedRoute';
import { role } from '../services/session';
import Page404 from '../pages/404/error';
import AddVendor from '../pages/vendor/add-vendor';
import BarcodeList from '../components/barcode-request/BarcodeList';
import BarcodeRequest from '../components/barcode-request/BarcodeRequest';
import BarcodeListEmp from '../components/barcode-list/BarcodeListEmp';
// import Homepage from '../components/index-components/homepage';

const RoutesApp = () => {
    const roles = role();

    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="product/:type/:id" element={<ProductUpload />} />
                <Route path="product-detail/:type/:id" element={<ProductDetail />} />
                <Route path="compare/:type" element={<Compare />} />
                <Route path="/addVendor" element={<AddVendor />} />
                <Route path='/page404' element={<Page404 />} />
                <Route path='/barcodeList' element={<BarcodeList />} />
                <Route path='/barcodeRequest' element={<ProtectedRoute user={roles}><BarcodeRequest /></ProtectedRoute>} />
                <Route path='/barcodeListEmp' element={<BarcodeListEmp />} />
                {/* <Route path='/homepage' element={<Homepage />} /> */}
            </Routes>


        </>
    );
};
export default RoutesApp;