export const productDetailsStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: { md: '10px 10px 10px 10px', sm: '15px 10px 10px 10px', xs: '10px 10px 10px 10px', lg: '10px 20px 0px 10px' },
    height: '70px',
    '& .back-icon': {
        cursor: 'pointer',
        display: "flex",
        alignItems: 'center',
        '& .icon': {
            width: { xs: '20px', md: '30px' },

        },
        '& .MuiTypography-h5': {
            color: '#000',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: { xs: '17px', sm: '18px', md: '24px' },
        },

    },
    '& .button-style-productview': {
        marginBottom: { xs: 1, md: 0 },
        marginRight: { md: 1 },
        borderRadius: '10px'
    },

    '& .icons': {
        marginLeft: '10px'
    }
};

export const productDetailsMainStyles = {
    padding: { xs: "90px 10px 30px 10px", sm: "110px 10px 30px 10px", md: "110px 10px 30px 10px" },
    width: "95%",
    margin: "0 auto",
    '& .label-wrapper-one': {
        fontSize: '16px',
        color: 'rgba(66, 66, 66, 1)',
        fontFamily: 'Montserrat-light',
        cursor: 'pointer'
    },
    '& .label-wrapper': {
        fontSize: { xs: '11px', sm: '18px', md: '18px' },
        color: 'rgba(66, 66, 66, 1)',
        fontFamily: 'Montserrat-light',

    },

    '& .product-desc': {
        fontFamily: 'Montserrat',
        fontWeight: '400',
        lineHeight: { md: '48px', xs: '30px' },
        letterSpacing: '0em',
        textAlign: 'left',
        fontSize: { xs: '13px', sm: '18px', md: '28px' },
    },
    '& .icon-button-box': {
        width: { md: '97%', xs: '100%', sm: '97%' },
        background: 'white',
        position: 'fixed',
        zIndex: 2,
        marginLeft: { xs: '-27px' }
    },

    '& .box-wrapper': {
        width: "100%",
        border: "1px solid grey",
        borderRadius: "12px",
        padding: '0px',
        margin: "0 auto",
        height: "100%vh",
        textAlign: "center",
        marginLeft: { xs: '-4px' }
    },

    '& .image-wrapper': {
        padding: { md: '0px 0px 0px 0px', xs: '0px 0px 0px 0px', sm: '0px 0px 0px 0px' },
        width: '100%',
        '& .glb-image-container': {
            width: '-webkit-fill-available',
            height: { xs: '40vh', md: 'auto', sm: 'auto' }
        },
        '& .glb-image': {
            position: 'relative',
            boxShadow: 'rgba(217, 217, 217, 1)',
            height: "98.5%", textAlign: "center", alignItems: "center",
        },
        "& .image-scroll-container ": {
            overflowX: { xs: 'auto', sm: 'initial', md: 'initial' },
            whiteSpace: { xs: 'nowrap', sm: 'initial', md: 'initial' },
            flexDirection: { xs: 'row', sm: 'initial', md: 'initial' },
            // justifyContent: 'flex-start', /* Adjust as needed */
        },
        "& .scroll-content": {
            display: { xs: 'flex', sm: 'initial', md: 'initial' },
            flexWrap: { xs: 'nowrap', sm: 'initial', md: 'initial' },
        },

        '& .bottom-scroll-image': {
            position: 'relative', flexShrink: 0,
            margin: { md: '0 8px', sm: '0 8px', xs: '0 0px' }
        },


        '& .slide-image': {
            borderRadius: "10px",
            width: '100px',
            height: "110px",
            position: 'relative',
            cursor: 'pointer',
        },
        '& .hover-container': {
            borderRadius: "10px",
            width: "100px",
            height: "110px",
            position: 'absolute',
            cursor: 'pointer',
            top: '0px',
            left: { xs: '2px', md: '0px', sm: '0px' },
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.6)"
            // position: 'absolute', cursor: 'pointer', top: '55px', left: {md:'50px',sm:'50px',xs:'50px'}, transform: 'translate(-50%, -50%)', backgroundColor: "rgba(0, 0, 0, 0.6)", color: "#fff", padding: {md:"49px",sm:"49px",xs:"0px"}, borderRadius: "10px", width: "150px", height: "110px"
        },

        '& .image-down-content': {
            mt: "20px",
            fontFamily: 'Montserrat-light',
            textAlign: "center",
            alignItems: "center"
        },
        '& .vendor-content': {
            display: "flex", alignItems: "center", textAlign: "center",
            margin: '20px 0px',
            '& .MuiGrid-root': {
                display: "flex", fontSize: "0.7rem", alignItems: "center",
                '& .vendor-typo': {
                    color: '#000',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    fontSize: { xs: '13px', sm: '18px', md: '28px' },
                },
            }
        },

    },
    '& .MuiTypography-body2': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '14px', sm: '18px', md: '22px' },
        wordWrap: 'break-word',
        textAlign: 'justify'
    },
    '& .MuiTypography-h5': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '14px', sm: '18px', md: '22px' },
        wordWrap: 'break-word',
    },
    '& .MuiTypography-h4': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '14px', sm: '18px', md: '25px' },
    },

    '& .product-view-modal': {
        border: "none", marginLeft: "2px"
    },

    '& .menuIcon': {
        marginTop: '10px',
        width: '98%'
    }



};
