import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import Layout from './layouts/layout';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import RoutesApp from './routes/route';
import ContextProvider from "./hooks/context-provider";
import { useEffect, useState } from 'react';
import { fetchAuthLogin } from './services/product.service';
import aadService from './authProvider';
import { authToken, tokens } from './services/session';
import AddVendor from "./pages/vendor/add-vendor";
import { Box, Button, Card, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { handleAuthValidate, setAuthSession } from './services/auth-services';
import Layout2 from './layouts/Layout2';
import ProductUpload from "./pages/product/upload/product-upload";
// `https://packaging-logic-dev.coherent.in/api/v1/auth/login?token=${sessionStorage.getItem('adal.idtoken')}`
function App() {
  const [idtoken, setIdtoken] = useState<any>(sessionStorage.getItem('adal.idtoken'));
  const [isLogin, setIsLogin] = useState(false);
  const [validateMsg, setValidateMsg] = useState<any>('');

  const fetchLogin = async () => {
    const getValidation = handleAuthValidate();
    setValidateMsg(getValidation);
    if (getValidation?.stage === 1) {
      if (sessionStorage.getItem('AccessToken')) {
        setIsLogin(true);
      }
      else {
        await fetchAuthLogin().then((response: any) => {
          setAuthSession(response);
          setIsLogin(true);
          toast.success(`You have successfully authenticated with Microsoft Login.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }).catch((error) => {
          toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      }
    }
    else if (getValidation !== undefined) {
      aadService.login();
    }

  };

  useEffect(() => {
    fetchLogin();
  }, []);


  return (
    <>
      <ToastContainer /> 
      {isLogin && <Router>
        <ThemeProvider theme={theme}>
          <ContextProvider>
            <Layout children={<RoutesApp />} />
              
          </ContextProvider>
        </ThemeProvider>
      </Router>
        ||
        <Layout2 children={
          <Box >
            {validateMsg.stage === 1 &&
              <Box sx={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
               <CircularProgress/> </Box>}
          </Box>
        } />
        
      }
    </>
  );
}

export default App;
