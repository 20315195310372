export const useStyle = {
    background: "#E5EEFF",
    boxShadow: "none",
    color: '#000000',
    position: 'fixed',
    zIndex: 1100,
    padding: '0.1em 0.1em 0.1em 0.1em',
    borderBottom: "2px solid rgb(24, 160, 223)",
    '& .logo-wrapper': {
        cursor: 'pointer'
    },
    '& .MuiToolbar-root': {
        paddingLeft: '0px',
        justifyContent: { xs: 'space-between', md: 'space-between' },

    },

    '& .MuiBox-root': {
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        '& .MuiTypography-h6': {
            fontWeight: '200',
            fontSize: { xs: '20px', md: '40px', sm: '30px', lg: '55px' },
            letterSpacing: { xs: '2px', md: '8px', sm: '2px', lg: '16px' },
            fontStyle: 'normal',
            lineHeight: '38px',
            fontVariant: 'all-small-caps',
            fontFamily: { xs: 'Montserrat-light', md: 'Montserrat-thin' },
            color: '#000000'

        },
        '& .MuiTypography-body1': {
            marginLleft: 'auto',
            fontWeight: '400',
            color: 'black',
        },
        '& .MuiSvgIcon-root': {
            fontSize: "40px", color: "#BA3D5F"
        }
    },





} as const;