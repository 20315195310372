import { useState, useEffect } from 'react';
import Context from './context';
import _, { result } from 'lodash';
import { useNavigate, useLocation } from "react-router-dom";
import { fetchCompareProductsById, fetchProductDropDownList } from '../services/product.service';
import { SKURANGEFILTER } from '../constants/filter-opitions.constants';
import queryString from 'query-string';
const keysArr = ["products", "shapes", "containers", "skuRange", "materials", "vendors"];
import { toast } from "react-toastify";
const ContextProvider = ({ children }) => {
    const router = useNavigate();
    const [compareList, setCompareList] = useState([]);
    const [filterFields, setFilterFields] = useState([]);
    const location = useLocation();
    const handleCompareProduct = async (product, actionType, page, type) => {
        if (actionType === "add" && compareList.length <= 2) {

            const concatData = _.concat(compareList, product);
            // const uniqBy = _.uniqBy(concatData, function (list) {
            //     return list;
            // });
            const uniqBy = _.uniqWith(concatData, _.isEqual);
            const mapped = _.map(uniqBy, function (elem) {
                return elem.productType + "_" + elem.id ;
            });
            const mappedString = JSON.stringify(mapped);
            localStorage.setItem('productIds', mappedString);
            setCompareList(uniqBy);
        }
        if (actionType === "remove") {
            if (page === "compare") {
                const rejectData = _.reject(compareList, product);
                const mapped = _.map(rejectData, (_.pick, 'id'));
                const mappedString = JSON.stringify(mapped);
                localStorage.setItem('productIds', mappedString);
                setCompareList(rejectData);
                if (rejectData?.length >= 2) {
                    return router(`/compare/${type}?id=${rejectData[0]?.productType + '_' + rejectData[0]?.id}&id=${rejectData[1]?.productType + '_' + rejectData[1]?.id}`);
                }
            } else {
                const rejectData = _.reject(compareList, product);
                const mapped = _.map(rejectData, (_.pick, 'id'));
                const mappedString = JSON.stringify(mapped);
                localStorage.setItem('productIds', mappedString);
                setCompareList(rejectData);

            }

        }
    };
    const handleFilterProduct = async () => {
        const arr = [];
        const UrlEndString = "data";
        await fetchProductDropDownList(UrlEndString).then((data) => {
            [data.data.keysTitle, data.data.keys, data.data.skuRange] = [keysArr, _.keys(data.data), SKURANGEFILTER];
            arr.push(data.data);
            setFilterFields(arr);
        }).catch((error) => {
            // console.log(error?.length !== 0, 'error');
            toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });

    };
    const handleCompareList = async (type) => {
        const res = localStorage.getItem('productIds');
        const ids = JSON.parse(res);
        const queryParams = queryString.parse(location?.search);
        const idValues = queryParams.id || '';
        if (idValues?.length >= 2) {
            await fetchCompareProductsById({ type, ids: idValues }).then((result) => {
                setCompareList(result?.data || []);
            });
        }
        else if (idValues?.length == 0 && (ids !== null || (ids?.length !== 0)) && compareList.length === 0) {
            const result = await fetchCompareProductsById({ type, ids });
            setCompareList(result?.data || []);
        }
    };

    useEffect(() => {
        handleFilterProduct();
    }, []);

    return (
        <Context.Provider value={{ compareList, handleCompareProduct, filterFields, handleCompareList, setCompareList }} >
            {children}
        </Context.Provider>
    );
};

export default ContextProvider;