import Header from "./header";
import { AppBar, Box, CssBaseline, Toolbar, Typography } from "@mui/material";
import { memo } from "react";
import { mediaQueryXs } from "../services/media-query-xs";
import { useStyle } from "./header.style";
const Layout2 = ({ children }: any) => {
    const logo = mediaQueryXs() ? "/assets/logo.png" : '/assets/cavinLogo.svg';
    return (
        <Box>
            <CssBaseline />
            <AppBar id="header-content" sx={useStyle} position="static">
                <Toolbar >
                    <div >
                        <img className="logo-wrapper" src={logo} alt="cavinlogo" width={mediaQueryXs() ? 200 : 70} height={mediaQueryXs() ? 70 : 50} />
                    </div>
                    {<Box sx={{ display: { lg: 'flex', alignItems:'center' }, }}>
                        <Typography style={{
                            fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '600'
                            , lineHeight: '30px', letterSpacing: '0.1em', textAlign: 'left', color: '#3161BE'
                        }} component="div"  >
                            PACKAGING LIBRARY

                        </Typography></Box>}
                    <Box></Box>
                </Toolbar >
            </AppBar>
            <Box component="main" sx={{ flexGrow: 1, }}>
                {children}
            </Box>
        </Box>



    );
};
export default memo(Layout2);