import Header from "./header";
import { Box, CssBaseline } from "@mui/material";
import { memo } from "react";
const Layout = ({ children }: any) => {

  return (
    <Box>
      <CssBaseline />
      <Header />
      <Box component="main" sx={{ flexGrow: 1, }}>
        {children}
      </Box>
    </Box>



  );
};
export default memo(Layout);