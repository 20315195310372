import { Typography, Box, Grid, Paper } from '@mui/material';
import ProductDetailsHead from '../product-detail-component/product-head-button';
import { compareStyles } from '../../styles/compare.style';

import { useNavigate } from "react-router-dom";

import View3D from "@egjs/react-view3d";
import "@egjs/react-view3d/css/view3d-bundle.min.css";
import Context from '../../hooks/context';
import { imageHandle } from '../../services/image-handle.service';
import { useContext } from 'react';





const compareContainer = {
    padding: { md: '20px 0px 0px 25px', xs: '10px 10px 30px 10px', sm: '35x 0px 30px 10px' },
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',

};

const compareBox = {
    position: 'relative',
    bottom: { xs: '10px' },
    flex: ' 0 0 auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: { md: "140px", xs: '130px', sm: '170px' },
};



const subHeader = {
    width: { xs: '100%', sm: '100%', md: '99%' }, position: 'fixed', zIndex: 2, background: 'white',
    marginLeft: { xs: '-1px', md: 'initial', sm: 'initial' },

};


const CompareProduct = ({ handleClickCompare, handleClickOpenModal, type, ids }: any) => {
    const contextData: any = useContext(Context);
    const router = useNavigate();
    return (
        <>
            <Grid sx={compareContainer} container spacing={1}>
                <Grid sx={subHeader} mt={{ md: 11, xs: 10, sm: 11 }}>
                    <ProductDetailsHead page='compare' data={contextData.compareList} handleClickCompare={handleClickCompare} />
                </Grid>

                <Box id="box-wrapper" p={{ md: 3, sm: 2, xs: 1 }} mt={{ md: 1, sm: 2, xs: 2 }} sx={compareBox}>
                    {
                        contextData.compareList?.map((item: any, index: any) => (
                            <Grid mt={{ md: 4, sm: 1.5, xs: 5 }} ml={{ xs: '2px', sm: '6px', md: '0px' }} key={index} xs={12} sm={contextData.compareList?.length === 2 ? 5.4 : 6} md={contextData.compareList?.length === 2 ? 5.4 : 3.9} sx={compareStyles} item spacing={1} >

                                <Grid component="div" className="box-wrapper">
                                    <img onClick={() => contextData.handleCompareProduct(item, 'remove', 'compare', type, ids)} className='compare-close-icon' width={30} height={30} alt="close image" src="/assets/Close_round.svg" />
                                    <Grid container xs={12} sm={12} md={12}>
                                        <Grid className="glb-image-container" xs={12} sm={12} md={contextData.compareList?.length === 2 ? 9.6 : 8.6}>
                                            <Box className="glb-image">
                                                <View3D autoplay={true} scrollable={true} tag="div" src={imageHandle(item.imageURLs)?.glb} poster="/public/vercel.svg" />
                                            </Box>
                                        </Grid>
                                        <Grid className={'image-scroll-container'} mt={{ xs: 3, md: 0, sm: 3 }} justifyContent={{ xs: 'space-between' }} container xs={12} sm={12} md={2}>
                                            <Grid className={'scroll-content'} xs={9} sm={9} >
                                                {imageHandle(item?.imageURLs)?.thumbImage.slice(0, 3).map((img: any, index: any) => (
                                                    <Grid xs={6} sm={6} key={index} style={{ position: 'relative', flexShrink: 0, margin: '0 8px' }}>
                                                        <img onClick={() => handleClickOpenModal(imageHandle(item?.imageURLs))} width={100} height={100} className="slide-image" src={img} alt={`Image`} />
                                                        {index === 2 && (
                                                            <Grid xs={4} sm={4} onClick={() => handleClickOpenModal(imageHandle(item?.imageURLs))} className="hover-container">
                                                                <Typography sx={{ marginTop: '-8px' }}>+{imageHandle(item.imageURLs)?.thumbImage.length - 3}</Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                ))}

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Typography onClick={() => handleClickOpenModal(imageHandle(item?.imageURLs))} className='images-content' variant="subtitle1" gutterBottom>
                                        Click to open expanded view
                                    </Typography>

                                    <Box className="vendor-content">
                                        <Grid container xs={6} sm={12} md={8} >
                                            <Typography className="vendor-text" variant="subtitle2">Vendor Name</Typography>
                                            <Typography textAlign={{ xs: 'initial' }} variant="h5" ml={{ md: 2, sm: 2 }}>
                                                {item?.vendorName || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid container justifyContent={{ md: "flex-start" }} xs={6} sm={12} md={4}>
                                            <Typography ml={{ sm: 1, xs: 1 }} variant="h4">
                                                ₹ {item?.price || 0} <span className="label-wrapper">per item</span>
                                            </Typography>
                                        </Grid>
                                    </Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} sm={12}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Product Code
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.productCode || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Product Type
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.productType || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Moulding Process
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.mouldingProcess || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Container Type
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.containerType || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Material
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.material || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Shape
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.shapes || '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                SKU Range
                                            </Typography>
                                            <Typography variant="h5" gutterBottom>
                                                {item?.skuRange || '-'} {item?.skuUnit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8} sm={6}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Over Fill Capacity
                                            </Typography>

                                            <Typography variant="h5" gutterBottom>
                                                {item?.overFillCapacity || '-'} ML
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} sm={6} >
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Weight
                                            </Typography>
                                            <Typography variant="h5" gutterBottom>
                                                {item?.weight || '-'} GM
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} sm={12}>
                                            <Typography className='vendor-text' variant="h6" gutterBottom>
                                                Product Description
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                {item?.productDescription || '-'}

                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }

                    {contextData.compareList?.length >= 1 && contextData.compareList?.length !== 3 && <>
                        <Grid mt={{ md: 4, sm: 1.5, xs: 5 }} xs={3} sm={0.9} md={0.9} sx={compareStyles} item spacing={1} >
                            <Paper onClick={() => router('/')} className='empty-box-wrapper' variant="outlined">
                                <Grid className='avatar-icon' container >
                                    <img src="/assets/add_square.png" width={30} height={30} alt='add image' />
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
                    }
                </Box>



            </Grid >
        </>
    );
};

export default CompareProduct;